import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactElement } from 'react';
import { ERoutePath } from '../../../../interface-adaptaters/interfaces/router/route.interface';
import { ETeam } from '../../../../modules/user/domain/user.entity';

interface IMenuList {
  router: ERoutePath;
  label: string;
  icon: ReactElement;
  permissions: ETeam[] | null;
  walterre?: boolean;
}

export const MENU_LIST: IMenuList[] = [{
  router: ERoutePath.LOGIN,
  label: 'Déconnexion',
  icon: <LogoutIcon />,
  permissions: null,
}, {
  router: ERoutePath.PROFIL,
  label: 'Profil',
  icon: <PersonIcon />,
  permissions: null,
}, {
  router: ERoutePath.SETTINGS,
  label: 'Réglages',
  icon: <SettingsIcon />,
  permissions: [ETeam.SUPER_ADMIN],
}];
