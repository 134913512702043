import { PaginateResponse } from '../../../libs/ddd/interfaces/base.interface';
import { EMethod, IQuery, UseAxios } from '../../../libs/useAxios';
import {
  BuildingProps,
  CreateProjectProps,
  GetOneProjectProps,
  PaginateProjectProps,
  ProjectProps,
  ProjectsListProps,
  StatsPhaseProjectProps,
  StatsPhaseProjectResponse,
  UpdateProjectBuildingProps,
  UpdateProjectCompaniesProps,
  UpdateProjectContractProps,
  UpdateProjectProps,
} from '../domain/project.entity';
import { SearchProjectsResponse } from '../responses/Search-project.response';
import { ProjectResponse } from '../responses/project.response';
import { ProjectRepositoryPort } from './project.repository.port';

export class ProjectRepository implements ProjectRepositoryPort {
  async updateProjectCompanies(id: number, project: UpdateProjectCompaniesProps): Promise<{ id: number; }> {
    const result = await new UseAxios<
    UpdateProjectCompaniesProps,
    { id: number }
    >({
      method: EMethod.PUT,
      url: `/project/${id}/society`,
      data: { ...project },
    }).exec();
    return result;
  }

  async updateProjectInterventionPerformed(id: number): Promise<{ id: number; }> {
    const result = await new UseAxios<
    { id: number}, { id: number }
    >({
      method: EMethod.PATCH,
      url: `/project/${id}/intervention-performed`,
      data: { id },
    }).exec();
    return result;
  }

  async getAllProjectSelect(): Promise<ProjectResponse[]> {
    const result = await new UseAxios<
    { page: number; limit: number },
    Promise<ProjectProps[]>
    >({
      method: EMethod.GET,
      url: '/projects/select',
    }).exec();
    return result;
  }

  async updateProjectBuildingInformation(id: number, project: UpdateProjectBuildingProps): Promise<{ id: number; }> {
    const result = await new UseAxios<
    UpdateProjectBuildingProps,
    { id: number }
    >({
      method: EMethod.PUT,
      url: `/project/${id}/building`,
      data: { ...project },
    }).exec();
    return result;
  }

  async updateProjectAnnualEnergy(id: number, project: ProjectProps): Promise<{ id: number; }> {
    const result = await new UseAxios<
    ProjectProps,
    { id: number }
    >({
      method: EMethod.PUT,
      url: `/project/${id}/annual-energy`,
      data: { ...project },
    }).exec();
    return result;
  }

  async statsPhaseProject(params: StatsPhaseProjectProps): Promise<StatsPhaseProjectResponse[]> {
    const result = await new UseAxios<StatsPhaseProjectProps, StatsPhaseProjectResponse[]>({
      method: EMethod.GET,
      url: '/projects/phase/stats',
      query: [
        params.technician ? { technician: params.technician?.toString() ?? '' } : {},
        params.admin ? { admin: params.admin?.toString() ?? '' } : {},
      ],
    }).exec();
    return result;
  }

  async updateProjectReportFinalized(id: number, isGenerateProgressPlan: boolean): Promise<{ id: number; }> {
    const result = await new UseAxios<
    { id: number, isReportFinalized: boolean, isGenerateProgressPlan: boolean }, { id: number }
    >({
      method: EMethod.PATCH,
      url: `/project/${id}/report-finalized`,
      data: { id, isReportFinalized: true, isGenerateProgressPlan },
    }).exec();
    return result;
  }

  async updateProjectContracts(
    id: number,
    project: UpdateProjectContractProps,
  ): Promise<{ id: number }> {
    const result = await new UseAxios<
    UpdateProjectContractProps,
    { id: number }
    >({
      method: EMethod.PATCH,
      url: `/project/${id}/contracts`,
      data: { ...project },
    }).exec();
    return result;
  }

  async getOneProject(id: number): Promise<GetOneProjectProps> {
    const result = await new UseAxios<{ id: number }, GetOneProjectProps>({
      method: EMethod.GET,
      url: '/project/admin',
      params: id.toString(),
    }).exec();
    return result;
  }

  async getEdl(id: number): Promise<string> {
    const result = await new UseAxios<{ id: number }, string>({
      method: EMethod.GET,
      url: `/boiler-room-inventory/${id.toString()}/edl/pdf`,
    }).exec();
    return result;
  }

  async getPec(id: number): Promise<string> {
    const result = await new UseAxios<{id: number}, string>({
      method: EMethod.GET,
      url: `/boiler-room-inventory/${id.toString()}/edl/simplified/pdf`,
    }).exec();
    return result;
  }

  async getMaterialJson(id: number): Promise<string> {
    const result = await new UseAxios<{id: number}, string>({
      method: EMethod.GET,
      url: `boiler-room-inventory/material/${id.toString()}/json`,
    }).exec();
    return result;
  }

  async getMaterialCsv(id: number): Promise<string> {
    const result = await new UseAxios<{id: number}, string>({
      method: EMethod.GET,
      url: `boiler-room-inventory/material/${id.toString()}/csv`,
    }).exec();
    return result;
  }

  async getMaterialXlsx(id: number): Promise<string> {
    const result = await new UseAxios<{id: number}, string>({
      method: EMethod.GET,
      url: `boiler-room-inventory/material/${id.toString()}/xlsx`,
    }).exec();
    return result;
  }

  async removeProject(id: number): Promise<{ id: number }> {
    const result = await new UseAxios<{ id: number }, { id: number }>({
      method: EMethod.DELETE,
      url: '/project',
      params: id.toString(),
    }).exec();
    return result;
  }

  async createProject(project: CreateProjectProps): Promise<{ id: number }> {
    const result = await new UseAxios<CreateProjectProps, { id: number }>({
      method: EMethod.POST,
      url: '/project',
      data: project,
    }).exec();
    return result;
  }

  async createBuilding(building: BuildingProps): Promise<{ id: number }> {
    const result = await new UseAxios<BuildingProps, { id: number }>({
      method: EMethod.POST,
      url: '/building',
      data: building,
    }).exec();
    return result;
  }

  async updateProject(
    id: number,
    project: UpdateProjectProps,
  ): Promise<{ id: number }> {
    const result = await new UseAxios<UpdateProjectProps, { id: number }>({
      method: EMethod.PUT,
      url: `/project/${id}`,
      data: project,
    }).exec();
    return result;
  }

  async paginateProjects({
    page, limit, search, order, sort,
  }: PaginateProjectProps): Promise<PaginateResponse<ProjectsListProps[]>> {
    const result = await new UseAxios<
    { page: number; limit: number },
    PaginateResponse<ProjectsListProps[]>
    >({
      method: EMethod.GET,
      url: '/projects/admin',
      query: [
        { page: page.toString() },
        { limit: limit.toString() },
        { order: order ?? 'createdAt' },
        { sort: sort ?? 'DESC' },
        search ? { search } : {},
      ],
    }).exec();
    return result;
  }

  async searchProjects(
    search: string,
    enabled: boolean,
  ): Promise<SearchProjectsResponse[]> {
    if (!enabled) return [];
    const query: IQuery[] = [
      { page: '1' },
      { limit: '20' },
      { order: 'name' },
      { sort: 'ASC' },
      search ? { search } : {},
    ];
    const result = await new UseAxios<{ search: string }, PaginateResponse<SearchProjectsResponse[]>>({
      method: EMethod.GET,
      url: '/projects',
      query,
    }).exec();
    return result.data;
  }

  async countProjects(): Promise<{ count: number }> {
    const result = await new UseAxios<void, { count: number }>({
      method: EMethod.GET,
      url: '/projects/count',
    }).exec();
    return result;
  }
}
