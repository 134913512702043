/* eslint-disable no-unused-vars */
import { GenericChoices } from '../../../libs/ddd/interfaces/base.interface';
import { PaginateProps, SearchProps, SortProps } from '../../../libs/interfaces';
import { ContactWithoutUserProps } from '../../contact/domain/contact.entity';
import { MediaProps } from '../../media/domain/media.entity';
import { ProjectProps } from '../../project/domain/project.entity';
import { ESubscriptionType } from '../../workspace/domain/workspace.entity';

export enum ETeam {
  TECHNICIAN = 'technician',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
}

export const ListTeam: GenericChoices<ETeam>[] = [
  { value: ETeam.ADMIN, label: 'Admin' },
  { value: ETeam.TECHNICIAN, label: 'Technicien' },
  { value: ETeam.SUPER_ADMIN, label: 'Super Admin' },
];

export interface UserProps {
  id: number;
  team: ETeam[];
  contact: ContactWithoutUserProps;
  profilPicture: MediaProps;
  technicianProjects?: Pick<ProjectProps, 'id'|'name'|'phase'|'interventionDate'>[];
  adminProjects?: Pick<ProjectProps, 'id'|'name'|'phase'|'interventionDate'>[];
  changePasswordToken?: string | null;
  changePasswordTimeOut?: string | null;
  workspace: {
    id: number;
    name: string;
    logo?: MediaProps;
    subscriptionType: ESubscriptionType;
    expirationDate: string;
  }
}

export type CreateUserProps = Omit<UserProps, 'changePasswordToken' | 'changePasswordTimeOut' | 'workspace' | 'id' >;
export type UpdateUserProps = Omit<UserProps, 'changePasswordToken' | 'changePasswordTimeOut' | 'workspace' >;
export type UpdateUserMeProps = Omit<UserProps, 'changePasswordToken' | 'changePasswordTimeOut' | 'workspace' | 'id' >;
export type UserWithoutContactProps = Omit<UserProps, 'contact'>;
export type PaginateUserProps = PaginateProps & Partial<SearchProps> & Partial<SortProps<UserProps>>;
export interface ProfilProps {
  user: UserProps;
  accessToken: string;
  refreshToken: string;
}

export interface LoginProps {
  email: string;
  password: string;
}

export interface LoginResponseProps {
  user: UserProps;
  accessToken: string;
  refreshToken: string;
}

export interface ForgotPasswordProps {
  email: string;
}

export interface ChangePasswordProps {
  changePasswordToken: string;
  password: string;
}
